<template>

<app-content :loading="is.loading || is.checking" :fullheight="true">

	<app-content-head title="Reports" :is-app="true" app-action="create-circle" v-on:action="onActionClick" />

	<app-content-body :no-padding="true" class="dashboard" v-if="!is.loading">

		<div class="dashboard-results-prompt" v-if="!items.length" v-on:click="onActionClick">

			<app-icon icon="empty" />
			<p>No active reports<small>Tap to create report</small></p>

		</div>

		<div class="dashboard-item" v-for="item in items" :key="item.id" v-on:click="onReportClick(item.id)">
		
			<div class="dashboard-item-thumb" v-bgimage="item.game.bgg_game.image"></div>
			
			<div class="dashboard-item-info">

				<div class="dashboard-item-info-name">{{ item.game.name }}</div>
				<div class="dashboard-item-info-version">Ref. {{ item.game.reference }}, Shelf {{ item.game.shelf }}</div>
				<div class="dashboard-item-info-user">{{ item.date | localDate('dddd HH:mm') }}</div>

			</div>
	
			<div class="dashboard-item-status" :data-theme="$constants.checkout.report.statusTheme[item.status]">{{ $constants.checkout.report.statusName[item.status] }}</div>

		</div>

	</app-content-body>

</app-content>

</template>

<script>

export default {

	components: {
	},

	data: function() {

		return {
			is: {
				checking: false,
				loading: true
			},
			items: []
		}

	},

	created: function() {

		// this.$pubsub.$on('scan', this.onScanDetected)

		this.load()

	},
	
	beforeDestroy: function() {

		// this.$pubsub.$off('scan', this.onScanDetected)

	},

	methods: {

		onReportClick: function(id) {

			this.$router.push({
				name: 'Reports.Report',
				params: {
					id: id
				}
			})

		},

		onActionClick: function() {

			this.$router.push({
				name: 'Reports.Create'
			})

		},
		
		load: function() {

			this.$api.get('reports/items').then(function(json) {

				this.items = json.collection 

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.dashboard {
	background-color: #fff;
}

.dashboard-results-prompt {
	color: #9aa2ac;
	position: absolute;
	left: 50%;
	text-align: center;
	top: 50%;
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.dashboard-results-prompt p small {
	display: block;
	font-size: 12px;
	font-weight: 300;
}

.dashboard.is-failed .dashboard-results-prompt {
	color: #fff;
}

.dashboard-results-prompt >>> .icon {
	font-size: 64px;
	margin-bottom: 10px;
}

.dashboard-results-prompt >>> p {
	font-size: 16px;
	line-height: 20px;
	font-weight: 400;
}

.is-desktop .dashboard-results-prompt:hover {
	color: #4d5b6e;
}

.dashboard-item {
	padding: 10px;
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
}

.dashboard-item:last-child {
	border-bottom-width: 0px;
}

.dashboard-item-thumb {
	width: 48px;
	height: 48px;
	border-radius: 4px;
	background-position: 50% 50%;
	background-size: cover;
	background-color: #ccc;
	flex-shrink: 0;
}

.dashboard-item-info {
	flex-grow: 1;
	padding: 0px 10px;
}

.dashboard-item-info-name {
	font-size: 14px;
	font-weight: 400;
	color: #000;
}

.dashboard-item-info-version {
	font-size: 12px;
	font-weight: 300;
	color: #333;
	margin-top: 3px;
}

.dashboard-item-info-user {
	margin-top: 4px;
	font-size: 12px;
	font-weight: 500;
	color: #003278;
}

.dashboard-item-status {
	flex-shrink: 0;
	font-weight: 400;
	font-size: 14x;
	background-color: #eee;
	padding: 4px;
	border-radius: 4px;
	color: #333;
}

.dashboard-item-status[data-theme="red"] {
	background-color: #c55b5b;
	color: #fff;
}

.dashboard-item-status[data-theme="yellow"] {
	background-color: #ffbe7a;
	color: #fff;
}

.dashboard-item-status[data-theme="green"] {
	background-color: #4faa80;
	color: #fff;
}

</style>
